/* WalletAnalytics.css */
.wallet-analytics-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .search-section {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .search-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #3461FF;
    border-radius: 8px;
    background: rgba(52, 97, 255, 0.1);
    color: #fff;
  }
  
  .search-button {
    padding: 10px 20px;
    background: #3461FF;
    border: none;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    transition: background 0.2s;
  }
  
  .search-button:hover {
    background: #2747cc;
  }
  
  .search-button:disabled {
    background: #1a2666;
    cursor: not-allowed;
  }
  
  .loading-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 40px;
  }
  
  .loader {
    border: 4px solid rgba(52, 97, 255, 0.3);
    border-top: 4px solid #3461FF;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .portfolio-overview {
    background: rgba(52, 97, 255, 0.1);
    border: 1px solid #3461FF;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .portfolio-header {
    margin-bottom: 20px;
  }
  
  .total-value {
    font-size: 2.5em;
    font-weight: bold;
    color: #fff;
  }
  
  .price-changes {
    display: flex;
    gap: 20px;
    margin-top: 10px;
  }
  
  .change {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.9em;
  }
  
  .positive {
    color: #4CAF50;
  }
  
  .negative {
    color: #f44336;
  }
  
  .chart-section {
    margin-top: 20px;
  }
  
  .timeframe-selector {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .timeframe-button {
    padding: 8px 16px;
    background: transparent;
    border: 1px solid #3461FF;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .timeframe-button.active {
    background: #3461FF;
  }
  
  .chain-card {
    background: rgba(52, 97, 255, 0.05);
    border: 1px solid rgba(52, 97, 255, 0.2);
    border-radius: 12px;
    margin-bottom: 20px;
    overflow: hidden;
  }
  
  .chain-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    cursor: pointer;
    transition: background 0.2s;
  }
  
  .chain-header:hover {
    background: rgba(52, 97, 255, 0.1);
  }
  
  .chain-info {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .chain-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  
  .chain-details h3 {
    margin: 0;
    font-size: 1.1em;
  }
  
  .chain-stats {
    font-size: 0.9em;
    color: #7B91B0;
  }
  
  .chain-value {
    font-weight: bold;
  }
  
  .chain-content {
    padding: 20px;
    border-top: 1px solid rgba(52, 97, 255, 0.2);
  }
  
  .token-filters {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  
  .filter-btn {
    padding: 6px 12px;
    background: transparent;
    border: 1px solid #3461FF;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .filter-btn.active {
    background: #3461FF;
  }
  
  .token-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .token-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background: rgba(52, 97, 255, 0.05);
    border-radius: 8px;
    transition: background 0.2s;
  }
  
  .token-row:hover {
    background: rgba(52, 97, 255, 0.1);
  }
  
  .token-left {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .token-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  
  .token-info {
    display: flex;
    flex-direction: column;
  }
  
  .token-name-row {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .token-symbol {
    font-weight: bold;
  }
  
  .token-name {
    color: #7B91B0;
    font-size: 0.9em;
  }
  
  .token-balance {
    font-size: 0.9em;
    color: #7B91B0;
  }
  
  .token-right {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 4px;
  }
  
  .token-value {
    font-weight: bold;
  }
  
  .token-price {
    font-size: 0.9em;
    color: #7B91B0;
  }
  
  .token-change {
    font-size: 0.9em;
    padding: 2px 6px;
    border-radius: 4px;
  }
  
  .nft-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .nft-card {
    background: rgba(52, 97, 255, 0.05);
    border: 1px solid rgba(52, 97, 255, 0.2);
    border-radius: 12px;
    overflow: hidden;
  }
  
  .nft-image-wrapper {
    aspect-ratio: 1;
    overflow: hidden;
  }
  
  .nft-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .nft-info {
    padding: 12px;
  }
  
  .nft-collection {
    font-size: 0.9em;
    color: #7B91B0;
  }
  
  .nft-name {
    font-weight: bold;
    margin-top: 4px;
  }
  
  .nft-floor {
    font-size: 0.9em;
    color: #7B91B0;
    margin-top: 4px;
  }
  
  .transactions-section {
    margin-top: 20px;
  }
  
  .transaction-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .transaction-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background: rgba(52, 97, 255, 0.05);
    border-radius: 8px;
  }
  
  .tx-type {
    font-weight: bold;
  }
  
  .tx-value {
    color: #7B91B0;
  }
  
  .tx-time {
    color: #7B91B0;
    font-size: 0.9em;
  }
  
  .tx-link {
    color: #3461FF;
    text-decoration: none;
  }
  
  .tx-link:hover {
    text-decoration: underline;
  }
  
  .token-stats-modal {
    position: fixed;
    background: #161A42;
    border: 1px solid #3461FF;
    border-radius: 8px;
    padding: 20px;
    width: 300px; /* Slightly wider for better readability */
    z-index: 999;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
  
  .token-stats-modal.active {
    opacity: 1;
    visibility: visible;
  }
  
  
  .token-stats-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .token-stats-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  
  .token-stats-title h4 {
    margin: 0;
    font-size: 1.1em;
    color: #fff;
  }

  .header-content {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .close-button {
    background: none;
    border: none;
    color: #7B91B0;
    font-size: 24px;
    cursor: pointer;
    padding: 0 4px;
    line-height: 1;
    border-radius: 4px;
    transition: all 0.2s ease;
  }
  
  .close-button:hover {
    color: #fff;
    background: rgba(123, 145, 176, 0.1);
  }
  
  .token-row {
    cursor: pointer;
  }
  
  .token-row:hover {
    background: rgba(52, 97, 255, 0.1);
  }
  
  .token-stats-title span {
    font-size: 0.9em;
    color: #7B91B0;
  }
  
  .token-stats-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 8px;
  }
  
  .token-stat-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
  }
  
  .token-stat-row span:first-child {
    color: #7B91B0;
  }
  
  .token-stat-links {
    display: flex;
    gap: 12px;
    margin-top: 12px;
  }
  
  .token-stats-modal .token-stat-links a {
    color: #3461FF;
    text-decoration: none;
    padding: 6px 12px;
    border-radius: 4px;
    background: rgba(52, 97, 255, 0.1);
    transition: all 0.2s ease;
    font-size: 0.9em;
  }
  
  .token-stats-modal .token-stat-links a:hover {
    background: rgba(52, 97, 255, 0.2);
    text-decoration: none;
    color: #4d75ff;
  }
  
  /* Add styles for the price change indicators */
  .positive {
    color: #00c853;
  }
  
  .negative {
    color: #ff3d00;
  }
  
  .empty-state {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
  }
  
  .empty-state-content {
    text-align: center;
  }
  
  .empty-icon {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .last-updated {
    text-align: right;
    font-size: 0.9em;
    color: #7B91B0;
    margin-top: 20px;
  }

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .loading-status-container {
    background: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
  }
  
  .loading-status {
    margin: 8px 0;
    padding: 8px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
  }
  
  .loading-status.success {
    color: #4CAF50;
  }
  
  .loading-status.error {
    color: #f44336;
  }
  
  .loading-status.loading {
    color: #2196F3;
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 998;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .refresh-button {
    background-color: #8d13b2; /* Blue background */
    color: #fff; /* White text */
    padding: 10px 20px; /* Padding for button size */
    border: none; /* Remove border */
    border-radius: 8px; /* Rounded corners */
    font-size: 16px; /* Text size */
    font-weight: 400; /* Bold text */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover */
  }
  
  .refresh-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: translateY(-2px); /* Slight lift on hover */
  }
  
  .refresh-button:active {
    background-color: #004494; /* Even darker blue when pressed */
    transform: translateY(0); /* Return to original position */
  }
  
  .refresh-button:disabled {
    background-color: #cccccc; /* Gray background for disabled state */
    cursor: not-allowed; /* Indicate disabled state */
    color: #666666; /* Gray text for disabled */
    transform: none; /* No transform */
  }
  



/* Mobile Styles */
@media screen and (max-width: 768px) {
    .wallet-analytics-container {
      padding: 10px;
    }
  
    .search-section {
      flex-direction: column;
      gap: 8px;
    }
  
    .search-input {
      width: 100%;
    }
  
    .timeframe-selector {
      overflow-x: auto;
      padding-bottom: 8px;
      margin: -4px;
      display: flex;
      flex-wrap: nowrap;
    }
  
    .timeframe-button {
      padding: 6px 12px;
      font-size: 0.9em;
      white-space: nowrap;
      flex: 0 0 auto;
      margin: 4px;
    }
  
    .portfolio-value-container {
      text-align: center;
    }
  
    .price-changes {
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px;
    }
  
    .change {
      font-size: 0.8em;
      padding: 3px 6px;
    }
  
    .token-filters {
      overflow-x: auto;
      padding-bottom: 8px;
      margin: -4px;
      display: flex;
      flex-wrap: nowrap;
    }
  
    .filter-btn {
      padding: 4px 10px;
      font-size: 0.9em;
      white-space: nowrap;
      flex: 0 0 auto;
      margin: 4px;
    }
  
    .chain-header {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      padding: 12px 15px;
    }
  
    .chain-info {
      display: flex;
      align-items: center;
      gap: 10px;
      flex: 1;
    }
  
    .chain-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  
    .chain-details h3 {
      font-size: 1.1em;
      margin: 0;
      text-align: left;
    }
  
    .chain-stats {
      text-align: left;
      font-size: 0.9em;
    }
  
    .chain-value {
      text-align: right;
      min-width: 80px;
    }
  
    .token-row {
      padding: 12px 15px;
    }
  
    .token-left {
      flex: 1;
      min-width: 0;
    }
  
    .token-info {
      min-width: 0;
    }
  
    .token-name-row {
      flex-wrap: wrap;
      gap: 4px;
    }
  
    .token-symbol {
      font-size: 1.1em;
    }
  
    /* Modal styles for mobile */
    .token-stats-modal {
      width: 94%;
      position: fixed;
      bottom: 20px;
      left: 3% !important;
      top: auto !important;
      transform: none !important;
      padding: 24px;
      background: #161A42;
      max-height: calc(100vh - 40px);
      overflow-y: auto;
    }
  
    .token-stats-header {
      margin-bottom: 24px;
      gap: 16px;
    }
  
    .token-stats-icon {
      width: 52px;
      height: 52px;
    }
  
    .token-stats-title h4 {
      font-size: 24px;
      margin-bottom: 8px;
    }
  
    .token-stats-title span {
      font-size: 16px;
      color: #7B91B0;
    }
  
    .token-stats-content {
      gap: 20px;
    }
  
    .token-stat-row {
      font-size: 16px;
    }
  
    .token-stat-row span:first-child {
      font-size: 16px;
      color: #7B91B0;
    }
  
    .token-stat-row span:last-child {
      font-size: 18px;
    }
  
    .token-stat-links {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
      margin-top: 24px;
    }
  
    .token-stat-links a {
      font-size: 16px !important;
      padding: 16px !important;
      text-align: center;
      border-radius: 8px;
    }
  
    .close-button {
      font-size: 28px;
      padding: 8px;
    }
  
    .chart-container {
      height: 250px;
      margin-top: 20px;
    }
  
    .total-value {
      font-size: 2em;
    }
  }
  
  /* Extra small devices */
  @media screen and (max-width: 380px) {
    .token-name-row {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .token-stats-modal {
      width: 96%;
      left: 2% !important;
      padding: 14px;
    }
  
    .token-stat-links {
      grid-template-columns: 1fr;
    }
  
    .token-stat-links a {
      padding: 10px !important;
    }
  
    .timeframe-button {
      padding: 4px 8px;
      font-size: 0.8em;
    }
  
    .total-value {
      font-size: 1.8em;
    }
  
    .chart-container {
      height: 220px;
      margin-bottom: 90px;
    }
  }

  /* Custom scrollbar styles for mobile */
@media screen and (max-width: 768px) {
    .token-filters {
      /* Existing styles */
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
      scrollbar-width: thin; /* Firefox */
      
      /* Hide default scrollbar */
      &::-webkit-scrollbar {
        height: 4px; /* Thin scrollbar */
      }
      
      &::-webkit-scrollbar-track {
        background: rgba(52, 97, 255, 0.1); /* Very subtle track */
        border-radius: 4px;
      }
      
      &::-webkit-scrollbar-thumb {
        background: rgba(52, 97, 255, 0.3); /* Subtle blue thumb */
        border-radius: 4px;
      }
  
      /* Hide scrollbar while not scrolling */
      scrollbar-color: transparent transparent;
      
      &:hover {
        scrollbar-color: rgba(52, 97, 255, 0.3) rgba(52, 97, 255, 0.1);
      }
      
      /* Add padding to prevent content touching scrollbar */
      padding-bottom: 8px;
      margin-bottom: -8px; /* Compensate for padding */
    }

    .token-list {
        margin-top: 16px;  /* Additional space below filters */
      }
  }

  .token-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #1a1b23;
    object-fit: contain;
  }
  
  .token-icon-placeholder {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #2A2E3D;
  }
  
  .chain-icon {
    min-width: 24px;
    min-height: 24px;
  }