/* Smooth scroll */
html {
  scroll-behavior: smooth;
}

/*------------------------*/

input:focus,
button:focus,
.form-control:focus {
  outline: none;
  box-shadow: none;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
}

/*----------step-wizard------------*/
.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

/*---------signup-step-------------*/
.bg-color {
  background-color: #333;
}

.signup-step-container {
  padding-bottom: 60px;
}

.wizard .nav-tabs {
  position: relative;
  margin-bottom: 0;
  border-bottom-color: transparent;
}

.wizard > div.wizard-inner {
  position: relative;
  margin-bottom: 100px;
  text-align: center;
}

.connecting-line {
  height: 3px;
  background: var(--primary-bg-color-3);
  position: absolute;
  width: 80%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 21px;
  z-index: 1;
}

.wizard .nav-tabs > li.active > a,
.wizard .nav-tabs > li.active > a:hover,
.wizard .nav-tabs > li.active > a:focus {
  color: #555555;
  cursor: default;
  border: 0;
  border-bottom-color: transparent;
}

span.round-tab {
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  border-radius: 50%;
  background: var(--primary-bg-color-3);
  z-index: 2;
  position: absolute;
  left: 0;
  text-align: center;
  font-size: 16px;
  color: #0e214b;
  border: 1px solid #ddd;
}

span.round-tab i {
  color: #555555;
}

.wizard li.active span.round-tab {
  color: var(--black-color);
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.wizard li.active span.round-tab i {
  color: #5bc0de;
}

.wizard .nav-tabs > li.active > a i {
  color: var (--primary-color);
}

.wizard .nav-tabs > li {
  width: 20%;
}

.wizard li:after {
  content: " ";
  position: absolute;
  left: 46%;
  opacity: 0;
  margin: 0 auto;
  bottom: 0px;
  border: 5px solid transparent;
  border-bottom-color: red;
  transition: 0.1s ease-in-out;
}

.wizard .nav-tabs > li a {
  width: 30px;
  height: 30px;
  margin: 20px auto;
  border-radius: 100%;
  padding: 0;
  background-color: transparent;
  position: relative;
  top: 0;
}

.wizard .nav-tabs > li a i {
  position: absolute;
  top: 50px;
  white-space: nowrap;
  left: 50%;
  transform: translate(-33%, -70%);
  font-size: 14px;
  color: var(--primary-bg-color-3);
}

.wizard .nav-tabs > li a:hover {
  background: transparent;
}

.wizard .tab-pane {
  position: relative;
  padding-top: 20px;
}

.wizard h3 {
  margin-top: 0;
}

.prev-step,
.next-step {
  font-size: 13px;
  padding: 8px 24px;
  border: none;
  border-radius: 4px;
  margin-top: 30px;
}

.next-step {
  background-color: var(--primary-color);
}

.skip-btn {
  background-color: #cec12d;
}

.step-head {
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.term-check {
  font-size: 14px;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 40px;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 40px;
  margin: 0;
  opacity: 0;
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 40px;
  padding: 0.375rem 0.75rem;
  line-height: 2;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 38px;
  padding: 0.375rem 0.75rem;
  line-height: 2;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.footer-link {
  margin-top: 30px;
}

.all-info-container {
}

.list-content {
  margin-bottom: 10px;
}

.list-content a {
  padding: 10px 15px;
  width: 100%;
  display: inline-block;
  background-color: #f5f5f5;
  position: relative;
  color: #565656;
  border-radius: 4px;
}

.list-content a[aria-expanded="true"] i {
  transform: rotate(180deg);
}

.list-content a i {
  text-align: right;
  position: absolute;
  top: 15px;
  right: 10px;
  transition: 0.5s;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #fdfdfd;
}

.list-box {
  padding: 10px;
}

.signup-logo-header .logo_area {
  width: 200px;
}

.signup-logo-header .nav > li {
  padding: 0;
}

.signup-logo-header .header-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-inline li {
  display: inline-block;
}

.pull-right {
  float: right;
}

/*-----------custom-checkbox-----------*/
input[type="checkbox"] {
  position: relative;
  display: inline-block;
  margin-right: 5px;
}

input[type="checkbox"]::before,
input[type="checkbox"]::after {
  position: absolute;
  content: "";
  display: inline-block;
}

@media (max-width: 767px) {
  .sign-content h3 {
    font-size: 40px;
  }

  .wizard .nav-tabs > li a i {
    display: none;
  }

  .signup-logo-header .navbar-toggle {
    margin: 0;
    margin-top: 8px;
  }

  .signup-logo-header .logo_area {
    margin-top: 0;
  }

  .signup-logo-header .header-flex {
    display: block;
  }
}

.stepcard {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(173, 173, 173, 0.2);
  border-radius: 10px;
  font-family: "Russo One", sans-serif;
  color: #c9c8c5;
  background: var(--primary-bg-color-2);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #c9c8c5;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: "Russo One", sans-serif;
}

.form-control:focus {
  color: #c9c8c5;
  background-color: transparent;
  border-color: rgb(241 70 104 / 25%);
  outline: 0;
}

.card label {
  color: #f0eee9;
  margin-bottom: 0.5rem !important;
}

small {
  font-size: 10px;
}

.brief {
  padding-bottom: 85px !important;
  border: 1px solid #fff;
  width: 100%;
  background: transparent;
  color: #fff;
  padding: 15px 15px;
}

.step-input-value {
  color: var(--primary-color);
}

.card-span {
  font-size: 13px;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #f0eee9;
  background-color: transparent;
  background-image: url(../../images/down.png);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select option {
  margin: 40px;
  background: #242525;
  color: #f0eee9;
}

select option:hover {
  color: whitesmoke !important;
  background: #bf5279 !important;
}

.react-datetime-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: 1px solid white;
  padding: 7px;
  border-radius: 5px;
}

svg.react-datetime-picker__clear-button__icon.react-datetime-picker__button__icon {
  stroke: #f0eee9;
}

svg.react-datetime-picker__calendar-button__icon.react-datetime-picker__button__icon {
  stroke: #f0eee9;
}

.react-datetime-picker__inputGroup__input {
  min-width: 0.54em;
  height: calc(100% - 2px);
  position: relative;
  padding: 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  color: #f0eee9;
  -moz-appearance: textfield;
}

.react-datetime-picker__inputGroup__input::placeholder {
  color: #f0eee9;
}

.react-datepicker__input-container input[type="text"] {
  width: 100%;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  height: 40px;
  padding-left: 10px;
}

.switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}

.switch input {
  display: none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}

input:checked + .slider {
  background-color: #66bb6a;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.wrapper {
  padding: 10px;
}

.image--cover {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 20px;
  object-fit: cover;
  object-position: center right;
}

.react-datepicker__close-icon::after {
  background-color: #a52e21 !important;
}

.row.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; /* Adjusted the gap property */
}

.col-12.col-md-6.col-lg-4.item.explore-item {
  display: flex;
  justify-content: center;
  margin-bottom: 10px; /* Reduced margin-bottom */
}



.project-list-container .row.items {
  display: flex;
  flex-wrap: wrap;
  gap: 5px; /* Reduce the spacing between the cards */
}

.project-list-container .item.explore-item {
  flex: 1 1 30%; /* Make sure the items take up available space */
}

.project-list-container .explore-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  margin-bottom: 10px;
}

.project-list-container .explore-btn {
  font-weight: normal; /* Ensure text is not bold */
  font-size: 14px; /* Make text smaller */
  color: #ffffff; /* Ensure the text color is white */
  background-color: transparent; /* Make background transparent */
  border: 1px solid #007bff; /* Add blue border */
  border-radius: 5px; /* Add border radius */
  padding: 10px 15px;
  margin-right: 10px;
  margin-bottom: 10px; /* Add margin-bottom to separate rows */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; /* Add transition for smooth effect */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px; /* Adjust the height */
  white-space: nowrap; /* Prevent text from wrapping */
}

.project-list-container .explore-btn:hover,
.project-list-container .explore-btn:focus,
.project-list-container .explore-btn:active,
.project-list-container .explore-btn.active {
  background-color: #007bff; /* Adjust according to your design */
  color: #fff; /* Ensure the text color is white */
  text-decoration: underline; /* Underline text when active */
}

.project-list-container .explore-link {
  color: #fff;
  text-decoration: none;
  font-weight: normal; /* Ensure text is not bold */
  font-size: 14px; /* Make text smaller */
  white-space: nowrap; /* Prevent text from wrapping */
}

.project-list-container .explore-link:hover,
.project-list-container .explore-link:focus,
.project-list-container .explore-link:active {
  color: #fff;
  text-decoration: none;
}

.project-list-container .explore-icon {
  margin-right: 5px;
}

.ad-container {
  text-align: right;
  margin: 10px 0; /* Reduced margin */
}

.project-list-container {
  padding: 0; /* Remove any default padding */
}

.explore-area {
  margin-top: 10px; /* Adjust the top margin */
}

.row.items {
  margin-top: 0;
  justify-content: space-between;
}

.buttons-and-ad-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px; /* Adjusted margin */
  margin-top: 20px; /* Adjusted margin */
}

.ad-container {
  text-align: right;
}

.project-list-container {
  padding: 0;
}

.row.items {
  margin-top: 0;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .buttons-and-ad-container {
    flex-direction: column;
    align-items: center;
  }
  .ad-container {
    margin-top: 10px;
    text-align: center;
  }

  .project-list-container .explore-menu {
    justify-content: center;
  }

  .project-list-container .explore-btn {
    width: 48%; /* Adjust the width */
    margin-right: 2%; /* Adjust the right margin for spacing */
  }

  .project-list-container .explore-btn:nth-child(2n) {
    margin-right: 0; /* Remove right margin for every second button */
  }

  .banners-container {
    flex-direction: column;
  }

  .animated-banner,
  .new-banner {
    margin-bottom: 5px; /* Add margin between the banners */
  }
}

.banners-container {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Adjust the space between the banners as needed */
}

.animated-banner,
.new-banner {
  flex: 1; /* Ensure banners take up equal space */
  color: white;
  padding: 8px;
  text-align: center;
  border-radius: 5px;
  margin: 0;
  line-height: 1.5;
  box-shadow: 1px 1px 0px white;
  font-size: 15px;
  font-family: "Arial", sans-serif;
}

.wobble {
  animation: wobble-animation 1s;
}

@keyframes wobble-animation {
  0%,
  100% {
    transform: translateX(0%);
  }
  15% {
    transform: translateX(-25%);
  }
  30% {
    transform: translateX(20%);
  }
  45% {
    transform: translateX(-15%);
  }
  60% {
    transform: translateX(10%);
  }
  75% {
    transform: translateX(-5%);
  }
}

.buttons-and-ad-container,
.animated-banner-container {
  margin: 1; /* Remove margins to avoid extra space */
}

.banner-iframe-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.banner-iframe {
  display: none;
}

@media (max-width: 767px) {
  .banner-iframe-selector {
    margin-top: 10px;
    justify-content: center;
  }

  .banner-iframe[data-max-width="428"] {
    display: block !important;
  }

  .banner-iframe[data-max-width="429"] {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .banner-iframe[data-max-width="428"] {
    display: none !important;
  }

  .banner-iframe[data-max-width="429"] {
    display: block !important;
  }
}

/* Custom styles for the project cards */
.date-detail span:first-child {
  /* Ensure styling is consistent after text replacement */
  font-weight: bold;
}

.date-detail span.ml-1 {
  /* Ensure date value elements have proper spacing */
  margin-left: 0.5rem;
}

/* Hide Start time and date for active sales */
.project-card.active .start-time-row {
  display: none;
}

/* Hide End time and date for upcoming sales */
.project-card.upcoming .end-time-row {
  display: none;
}

/* Hide Start time and date for ended sales */
.project-card.ended .start-time-row {
  display: none;
}

/* Hide both Start and End time and dates for canceled sales */
.project-card.canceled .start-time-row,
.project-card.canceled .end-time-row {
  display: none;
}

/* New styles to ensure 4 cards per row on desktop and 1 card per row on mobile */
.row.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}



@media (min-width: 576px) {
  .row.items > .col-12.col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .row.items > .col-12.col-sm-6.col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}


/* Ensure 4 cards per row on desktop and 1 card per row on mobile */
.row.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}



.col-12.col-sm-6.col-lg-3 {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .row.items > .col-12.col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .row.items > .col-12.col-sm-6.col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}


/* Ensure 4 cards per row on desktop and 1 card per row on mobile */
.row.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.project-list-container .project-card {
  width: 100%;
  max-width: 100%;
  border: 0px solid #000000;
  border-radius: 10px;
  overflow: hidden;
  background-color: #1b1b1b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 575.98px) {
  .row.items > .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .row.items > .col-12.col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .row.items > .col-12.col-sm-6.col-md-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media (min-width: 992px) {
  .row.items > .col-12.col-sm-6.col-md-4.col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
/* Ensure 4 cards per row on desktop and 1 card per row on mobile */
.row.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.project-list-container .project-card {
  width: 100%;
  border: 1px solid #000000;
  border-radius: 10px;
  overflow: hidden;
  background-color: #1b1b1b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 575.98px) {
  .row.items > .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .row.items > .col-12.col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .row.items > .col-12.col-sm-6.col-md-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media (min-width: 992px) {
  .row.items > .col-12.col-sm-6.col-md-4.col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

/* Remove bottom margin from button group */
.buttons-and-ad-container .explore-menu.btn-group {
  margin-bottom: 2;
}

/* Remove top margin from banner container */
.buttons-and-ad-container .banner-iframe-selector {
  margin-top: 2;
  padding-top: 2;
}

/* Adjust margin and padding for individual elements if needed */
.buttons-and-ad-container .explore-btn {
  margin-bottom: 2px; /* Adjust this value as needed */
}

.buttons-and-ad-container {
  margin-bottom: 2;
}



